html::-webkit-scrollbar {
  display: none;
}

html {
  -ms-overflow-style: none;
}

*:focus {
  box-shadow: none !important;
}
